<template>
  <section id="add-rule">
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                Просмотр правила
              </b-card-title>
            </div>
          </b-card-header>
          <b-card-body>
            <b-overlay
              variant="white"
              :show="ruleLoading"
              spinner-variant="primary"
              blur="0"
              opacity=".75"
              rounded="sm"
            >
              <b-tabs pills>
                <b-tab
                  title="Детали правила"
                  active
                >
                  <b-card-text>
                    <br>
                    <div v-if="rule != null">
                      <b-list-group>
                        <b-list-group-item class="flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              Название
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            {{ rule.name }}
                          </b-card-text>
                        </b-list-group-item>

                        <b-list-group-item class="flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              Аккаунты
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            Яндекс Директ: rusbuketads
                          </b-card-text>
                        </b-list-group-item>

                        <b-list-group-item class="flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              Кампании
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            <b-col cols="4">
                              <b-table
                                hover
                                :data="campDatas"
                                :paginated="campDatas.length > 10"
                                :columns="campsResultColumns"
                            >
                              <b-table-column
                                  v-slot="props"
                                  field="name"
                                  width="170"
                                  sortable
                              >
                                {{ props.row.name }}
                              </b-table-column>
                            </b-table>
                            </b-col>
                          </b-card-text>
                        </b-list-group-item>

                        <b-list-group-item
                          v-if="types.includes(rule.typeBid)"
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              Тип корректировки
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            <div v-if="rule.typeBid === 'automatic-fits'">
                              Ключи укладываются в KPI. Увеличение ставки на 15%.
                            </div>
                            <div v-if="rule.typeBid === 'automatic-discrepancy'">
                              Ключи не укладываются в KPI. KPI отличается меньше чем на 70%.
                              Уменьшение ставки на 15%.
                            </div>
                            <div v-if="rule.typeBid === 'automatic-fits'">
                              Ключевой показатель CPO. Ключи не укладываются в KPI. KPI отличатся больше чем на 70%.
                              Расчет по формуле: Cтавка = Целевая стоимость конверсии * Конверсия по ключу * 1,2
                            </div>
                          </b-card-text>
                        </b-list-group-item>

                        <b-list-group-item
                          v-if="!types.includes(rule.typeBid)"
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              Частота применения:
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            Один раз в день
                          </b-card-text>
                        </b-list-group-item>

                        <b-list-group-item
                          v-if="!types.includes(rule.typeBid)"
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              Условия
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            <div
                              v-for="(item, i) in condition"
                              :key="item.id + '_' + i"
                            >
                              <h6 class="mb-1">
                                Условие № {{ i + 1 }}
                              </h6>
                              <div
                                v-for="(subItem, subIndex) in item.subItems"
                                :key="item.id + '_' + subIndex"
                                class="mb-1"
                              >
                                Дата: {{ subItem.rangeData }} <br>
                                Условие по статистике: {{ subItem.traffic.text }} {{ subItem.cost.text }} {{ subItem.val }}
                                <br>
                                <b-badge
                                  v-show="item.subItems.length !== subIndex + 1"
                                  class="mt-1"
                                  variant="success"
                                >
                                  <span>И</span>
                                </b-badge>
                              </div>
                            </div>
                          </b-card-text>
                        </b-list-group-item>

                        <b-list-group-item
                          v-if="!types.includes(rule.typeBid)"
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              Действия
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            <div v-if="rule.typeBid === 'percent'">
                              Изменение ставки на процент
                            </div>
                            <div v-else>
                              Установить определенное значение ставки
                            </div>
                          </b-card-text>
                        </b-list-group-item>

                        <b-list-group-item
                          v-if="!types.includes(rule.typeBid)"
                          class="flex-column align-items-start"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              Ставка
                            </h5>
                          </div>
                          <b-card-text class="mb-1">
                            Макс/мин ставка: {{ rule.maxBid != 0 ? rule.maxBid : 'отсутствует' }} <br>
                            <div v-if="rule.typeBid !== 'percent'">
                              Ставка: {{ rule.value }}
                            </div>
                            <div v-else>
                              Процент: {{ rule.value }}% <br>
                            </div>
                          </b-card-text>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab title="История изменений">
                  <b-card-text>
                    <br>
                    <div v-if="rule != null && bidsHistory.length !== 0">
                      <app-timeline>
                        <transition-group
                          name="fade"
                          mode="out-in"
                        >
                          <template
                            v-for="history in bidsHistory"
                          >
                            <app-timeline-item
                              :key="history.id + 1"
                              title="Старт"
                              subtitle="Запущен процесс применения правила"
                              icon="ClockIcon"
                              :time="new Date(history.executeStartTime).toLocaleDateString() + ' ' +new Date(history.executeStartTime).toLocaleTimeString()"
                              variant="secondary"
                            />
                            <app-timeline-item
                              v-show="history.ruleBidsStatus === 2"
                              :key="history.id + 2"
                              variant="warning"
                              icon="AlertCircleIcon"
                            >
                              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                <h6>Предупреждение</h6>
                                <small class="text-muted">{{ new Date(history.executeStartTime).toLocaleDateString() + ' ' +new Date(history.executeStartTime).toLocaleTimeString() }}</small>
                              </div>
                              <p><b>Ключи не прошли фильтрацию</b></p>

                              <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">

                                <div>
                                  <b-list-group
                                    flush
                                    class="mt-1"
                                  >
                                    <b-list-group
                                      v-show="history.descriptionOfConditions != null"
                                      class="mb-2"
                                    >
                                      <b-list-group-item
                                        v-for="(kDescription, index) in splitHistoryWarnings(history)"
                                        v-show="kDescription != ''"
                                        class="flex-column align-items-start"
                                      >
                                        <div class="d-flex w-100 justify-content-between">
                                          <h5 class="mb-1">
                                            Условие №{{ index }}
                                          </h5>
                                        </div>
                                        <b-card-text class="mb-1">
                                          {{ kDescription }}
                                        </b-card-text>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </b-list-group>
                                </div>
                              </div>
                            </app-timeline-item>
                            <app-timeline-item
                              v-show="history.executeEndTime !== null && history.ruleBidsStatus !== 2"
                              :key="history.id + 3"
                              variant="success"
                              icon="InfoIcon"
                            >
                              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                <h6>Успех</h6>
                                <small class="text-muted">{{ new Date(history.executeEndTime).toLocaleDateString() + ' ' +new Date(history.executeEndTime).toLocaleTimeString() }}</small>
                              </div>
                              <p>Правило успешно применено!</p>

                              <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                <div>
                                  <b-col
                                    v-if="!types.includes(rule.typeBid)"
                                    cols="10"
                                    class="mt-1"
                                  >
                                    <b-link
                                      v-b-toggle="'keywords-info-' + history.id"
                                      style="text-decoration: dotted underline;
                                        text-underline-offset: 3px;"
                                    >
                                      Показать информацию по найденным ключам
                                    </b-link>
                                    <b-collapse :id="'keywords-info-' + history.id">
                                      <b-list-group
                                        flush
                                        class="mt-1"
                                      >
                                        <b-list-group
                                          v-show="history.descriptionOfConditions != null"
                                          class="mb-2"
                                        >
                                          <b-list-group-item
                                            v-for="(kDescription, index) in splitHistoryWarnings(history)"
                                            v-show="kDescription != ''"
                                            class="flex-column align-items-start"
                                          >
                                            <div class="d-flex w-100 justify-content-between">
                                              <h5 class="mb-1">
                                                Условие №{{ index }}
                                              </h5>
                                            </div>
                                            <b-card-text class="mb-1">
                                              {{ kDescription.replace(/,\s*$/, "") }}
                                            </b-card-text>
                                          </b-list-group-item>
                                        </b-list-group>
                                      </b-list-group>
                                    </b-collapse>
                                  </b-col>
                                  <b-col
                                    cols="12"
                                    class="mt-1"
                                  >
                                    <b-link
                                      v-b-toggle="'report-list-with-icon-' + history.id"
                                      style="text-decoration: dotted underline;
                                        text-underline-offset: 3px;"
                                    >
                                      Показать таблицу измененных ставок
                                    </b-link>
                                    <b-collapse :id="'report-list-with-icon-' + history.id">
                                      <b-table
                                        class="mt-1"
                                        :data="createSubTittle(history)"
                                        :columns="bidsResultColumns"
                                        :paginated="true"
                                      >
                                        <b-table-column
                                          v-slot="props"
                                          field="before"
                                          width="170"
                                          sortable
                                        >
                                          {{ props.row.before }}
                                        </b-table-column>
                                      </b-table>
                                    </b-collapse>
                                  </b-col>
                                </div>
                                <div>
                                  <b-button
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    size="sm"
                                    :disabled="history.ruleBidsStatus === 1"
                                    :variant="history.ruleBidsStatus === 1 ? 'success' : 'outline-primary'"
                                    @click="cancelRule(history.id)"
                                  >
                                    <feather-icon
                                      v-show="history.ruleBidsStatus !== 1"
                                      icon="RotateCcwIcon"
                                      class="mr-50"
                                    />
                                    {{ history.ruleBidsStatus === 1 ? 'Правило отменено' : 'Отменить правило' }}
                                  </b-button>
                                </div>
                              </div>
                            </app-timeline-item>
                            <app-timeline-item
                              v-show="history.ruleBidsStatus === 1"
                              :key="history.id + 4"
                              title="Правило отменено"
                              :subtitle="'Все ставки по ключам вернулись к исходным значениям на момент создания правила: ' + new Date(history.cancelTime).toLocaleDateString() + ' ' +new Date(history.executeStartTime).toLocaleTimeString() "
                              icon="RotateCcwIcon"
                              :time="new Date(history.cancelTime).toLocaleDateString() + ' ' +new Date(history.cancelTime).toLocaleTimeString()"
                            />
                          </template>
                        </transition-group>
                      </app-timeline>
                    </div>
                    <div
                      v-else
                      class="misc-inner p-2 p-sm-3"
                    >
                      <div class="w-100 text-center">
                        <h2 class="mb-1">
                          Историй запуска правила еще нет 🕵🏻‍♀️
                        </h2>
                        <p class="mb-2">
                          Ой! 😖 Перейдите на  <b-link :to="{ path:`/dashboard/rules` }">
                            страницу с таблицей правил
                          </b-link>
                          для запуска.
                        </p>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import {
  BRow,
  BCol,
  BCard,
  BTab,
  BLink,
  BTabs,
  BButton,
  BCardHeader,
  BCollapse, BBadge,
  BListGroup, BListGroupItem,
  BCardBody,
  BCardTitle, VBToggle, VBTooltip,
  BCardSubTitle, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    AppTimeline,
    AppTimelineItem,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BBadge,
    BCollapse,
    BCardSubTitle,
    BOverlay,
    BLink,
    BListGroup,
    BListGroupItem,
    BTab,
    BTabs,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    ruleId: [String, Number],
  },
  data() {
    return {
      campDatas: [],
      types: [
        'automatic-discrepancy',
        'automatic-fits',
        'automatic-cpo-discrepancy',
      ],
      rule: null,
      bidsHistory: [{ id: 1, cancelStatus: 1, executeStartTime: 2 }],
      condition: null,
      bidsResultData: null,
      ruleLoading: false,
      campsResultColumns: [
        {
          field: 'name',
          label: 'Название',
        },
      ],
      bidsResultColumns: [
        {
          field: 'bidsName',
          label: 'Название',
        },
        {
          field: 'before',
          label: 'Результат ДО',
          sortable: true,
        },
        {
          field: 'after',
          label: 'Результат ПОСЛЕ',
        },
      ],
    }
  },
  computed: {
  },
  mounted() {
    this.ruleLoading = true
    this.$http.get(`/adrules/details/${this.ruleId}`)
      .then(response => {
        const dataForParse = response.data
        console.log(dataForParse)
        this.rule = dataForParse
        this.condition = JSON.parse(dataForParse.condition)

        const parseStats = []
        JSON.parse(this.rule.campaigns).forEach(item => {
          const stat = {
            name: item,
          }
          parseStats.push(stat)
        })
        this.campDatas = parseStats
      })

    this.$http.get(`/adrules/history/${this.ruleId}`)
      .then(response => {
        const dataForParse = response.data
        console.log(dataForParse, 'history')
        this.bidsHistory = dataForParse
        this.ruleLoading = false
      })
  },
  created() {
  },
  methods: {
    createSubTittle(val) {
      const subKeys = []

      if (val !== null) {
        const beforeParams = val.currentBids.split(',')
        const afterParams = val.changedBids.split(',')
        const keywords = val.changedKeywords.split(',')

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < beforeParams.length; i++) {
          const itemBefore = beforeParams[i]
          const itemAfter = afterParams[i]
          const keyword = keywords[i]

          subKeys.push({
            bidsName: keyword,
            before: itemBefore / 1000000,
            after: itemAfter / 1000000,
          })
        }
        return subKeys
      }
    },
    splitHistoryWarnings(history) {
      if (history != null && history.descriptionOfConditions != null) {
        // super dumb and need refactoring
        return history.descriptionOfConditions.split('@')
      }
    },
    addToItems() {

    },
    cancelRule(ruleId) {
      this.$http.get(`/yandex-direct/cancel-rule/${ruleId}`)
        .then(response => {
          console.log(response, 'response')
          this.bidsHistory.find(c => c.id === ruleId).ruleBidsStatus = response.data.status
          this.bidsHistory.find(c => c.id === ruleId).cancelTime = response.data.cancelTime
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Правило отменено',
              icon: 'RotateCcwIcon',
              variant: 'success',
            },
          }, {
            position: 'top-center',
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.box{
  padding: 15px;
  border: 1px solid black;
  margin-bottom: 15px;
}
</style>
